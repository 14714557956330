import { css } from '@emotion/core';
import media from '../utils/media';

export const overflowVisible = css`
   {
    overflow: visible;
  }
`;

/* dropdown classes */
export const dropdownParent = css`
   {
    overflow: visible;
  }
`;

export const arrow = css`
   {
    border: solid #999;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    position: absolute;
    right: 10px;
  }
`;

export const arrowOpen = css`
   {
    top: 7px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
`;

export const arrowClosed = css`
   {
    top: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`;

export const dropdownRoot = css`
   {
    /*position: absolute;
    width: inherit;*/
  }
`;

export const dropdownControlFilter = css`
   {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 3px 5px 3px 10px;
    transition: all 200ms ease;
    :hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
  }
`;

export const dropdownControlSort = css`
   {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 3px 5px 3px 10px;
    transition: all 200ms ease;
    :hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
  }
`;

export const dropdownMenu = css`
   {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
`;

export const dropdownPlaceholder = css` {
`;

export const searchInputClass = css`
   {
    line-height: 175%;
    width: inherit;
    font-size: 18px;
    ${media.small`
    font-size: 18px;
  `};
    ${media.mid`
   font-size: 20px;
  `};
    ${media.large`
    font-size: 20px;
  `};
  }
`;

/*
// example dropdown classes from react-dropdown sample

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1.2);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.my-custom-class {
  border: 2px solid red;
  font-weight: bold;
  color: purple;
}

 */
/* dropdown classes end */
