import { css } from '@emotion/core';
import colors from '../utils/colors';

export const pageIcon = css`
  text-align: center;
`;

export const pageButton = css`
  cursor: pointer;
  margin: 5px;
  width: 50px;
  height: 50px;
  padding: 9px;
  text-align: center;
  padding: 4px;
  color: ${colors.secondary};
  border-width: 1px;
  border-color: ${colors.tertiary};
  border-radius: 3px;
  border-style: solid;
  & :hover {
    background-color: ${colors.secondary};
    color: ${colors.primary};
    border-color: ${colors.tertiary};
  }
  & svg {
    margin-top: 5px;
  }
`;
